import React, { useEffect, useState } from "react";

import { BaseProps, ToastType } from "./types";
import { showToast } from "./util/Toast";
import TopLoader from "./components/TopLoader";

export default function RedirectUrl(props: BaseProps) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        //@ts-ignore
        const shortUrlSuffix = props.match.params["id"];

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/v1/url/${shortUrlSuffix}`,
          {
            method: "GET",
            redirect: "follow", // This will automatically follow redirects
          }
        );

        if (response.redirected) {
          window.location.href = response.url;
        } else {
          showToast(ToastType.ERROR, "Something went wrong! Please try again");
        }

        setLoading(false);
      } catch (error) {
        console.log({ error });
        showToast(ToastType.ERROR, "Something went wrong! Please try again");
      }
    };

    init();
  }, []);

  return loading ? <TopLoader></TopLoader> : <div>Redirecting .....</div>;
}
