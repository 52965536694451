import React from "react";
import Navbar from "./layout/Navbar";

export default function TopLoader() {
  return (
    <React.Fragment>
      <div className="container">
        <Navbar ></Navbar>
        <div className="container">
          <div className="text-center" style={{ marginTop: "6rem" }}>
            <div
              className="spinner-border"
              role="status"
              style={{ color: "#5a5af3" }}
            ></div>
            <br></br>
            <h6>Loading</h6>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
