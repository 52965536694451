import "react-toastify/dist/ReactToastify.css";

import React, { Fragment } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RedirectUrl from "./RedirectUrl";

function App() {
  return (
    <Fragment>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route exact path="/:id" component={RedirectUrl}></Route>
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
