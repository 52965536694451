import React from "react";

import { RouteComponentProps } from "react-router-dom";

export enum ToastType {
  "SUCCESS" = "SUCCESS",
  "ERROR" = "ERROR",
}

export interface BaseProps extends React.PropsWithChildren {
  history: RouteComponentProps["history"];
  location: RouteComponentProps["location"];
  match: RouteComponentProps["match"];
}
