import { toast } from "react-toastify";
import { ToastType } from "../types";

export const showToast = (
  type: ToastType,
  message: string,
  durationInMs: number = 5000
) => {
  if (type === ToastType.ERROR) {
    toast.error(message, {
      position: "top-center",
      autoClose: durationInMs,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return;
  } else if (type === ToastType.SUCCESS) {
    toast.success(message, {
      position: "top-center",
      autoClose: durationInMs,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
